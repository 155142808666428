<template>
  <div class="view-wrapper align-center overflow-y-auto">
    <v-card class="px-2 ma-2 mt-12 elevation-5" style="width: 350px;">
      <v-card-title class="justify-center">
        {{ $localize('login') }}
      </v-card-title>
      <v-card-text @keydown.enter="signIn">
        <!--                <v-text-field v-model="item.email"-->
        <!--                              :rules="[rules.required, rules.email]"-->
        <!--                              label= "Электронная почта"-->
        <!--                              outlined-->
        <!--                              clearable-->
        <!--                ></v-text-field>-->
        <v-text-field v-model="item.phone"
                      :rules="[rules.required]"
                      :label= "$localize('telephone_number')"
                      prepend-inner-icon="mdi-phone"
                      return-masked-value
                      v-mask="'+7(###)-###-##-##'"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required]"
                      :type="showPassword ? 'text' : 'password'"
                      :label= "$localize('password')"
                      outlined
                      clearable
                      dense
                      @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0 mb-7">
        <v-row class="align-center"
               justify="space-around">
          <v-btn text
                 @click="$router.push('/auth/forgot-password')"
          >
            {{ $localize('forget_password?') }}
          </v-btn>
          <v-btn dark
                 color="success"
                 style="display: flex; flex: 0.5;"
                 :outlined="!isFormFilled"
                 @click="signIn"
          >
            {{ $localize('sign_in') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LoginInvestor",
  data(){
    return{
      item: {
        // email: undefined,
        phone: undefined,
        password: undefined,
      },
      showPassword: false,

      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
        email: value => {
          return this.$testRegex('email', value) || this.$localize('enter_email')
        },
      },
    }
  },

  computed:{

    isFormFilled(){
      // if (!this.item.email) return false;
      if (!this.item.phone) return false;
      else if (!this.item.password) return false;
      // else if(this.item.email && !emailRegex.test(this.item.email) ) return false;
      else if(this.item.phone && this.item.phone.length < 17) return false;
      return true;
    },
  },

  methods:{
    async signIn(){
      if(this.isFormFilled){
        let cloned = this.$lodash.clone(this.item)
        cloned.phone = cloned.phone.replace(/[-+()]/g, '').substring(1)
        this.$appUtils.setOverlay(true)
        const res = await this.$fetch.post('api/auth/sign-in', this.$vm2js(cloned), true);
        this.$appUtils.setOverlay(false)
        if(res && res.token) {
          let user = JSON.parse(JSON.stringify(res));
          user.logAs = 'investor'
          this.$setCookie('user', user, { expires: 1 });
          this.$router.push('/')
        }else this.$store.dispatch('setError', {type: 'error', message: 'wrong_login_or_password'})
      }else this.$store.dispatch('setError', {type: 'warning', message: 'fill_all_fields_correctly'})
    },
  }
}
</script>

<style>

</style>
